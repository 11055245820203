import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from './store';

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useUsersAppSelector = () => useAppSelector((state) => state.users);
export const useCalendarAppSelector = () => useAppSelector((state) => state.calendar);
export const usePriceSettingsAppSelector = () => useAppSelector((state) => state.price_settings);
export const useHotelSelectionAppSelector = () => useAppSelector((state) => state.hotel_selection);
