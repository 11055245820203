import React, { useEffect } from 'react';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useRouter } from 'next/router';
import { useHotelSelectionAppSelector } from '../client/app/hooks';

const Index: React.FC = () => {
  const { selectedHotel } = useHotelSelectionAppSelector();
  const router = useRouter();
  useEffect(() => {
    if (selectedHotel?.key) router.push(`/schedule`); // Redirect to the new default page
  }, [router, selectedHotel]);
  return <div />;
};

export const getStaticProps = async ({ locale }: { locale: string }) => ({
  props: {
    ...(await serverSideTranslations(locale, ['common', 'dashboard'])),
  },
});

export default Index;
